import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@mui/icons-material';
import { Box, Container, IconButton, InputAdornment, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppButton from '../../components/materials/actions/AppButton';
import AppLayout from '../../components/materials/containers/AppLayout';
import AppTextField from '../../components/materials/forms/AppTextField';
import AccountHeader from '../../components/materials/navigation/AccountHeader';
import { ToastContext } from '../../context/toastContext';
import {
  useAddAdminMutation,
  useAddDataCollectorMutation,
  useAddDataLeadMutation,
  useValidateInvitationLinkMutation,
} from '../../utils/redux/api';
import { clearAuth } from '../../utils/redux/authSlice';
import { RolesEnum } from '../../utils/types/Roles';

interface PasswordError {
  minLength: boolean;
  uppercase: boolean;
  number: boolean;
  symbol: boolean;
}

interface User {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

const AccountCreation = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');

  const [isPasswordInteracted, setIsPasswordInteracted] = useState(false);
  const [isPasswordConfirmInteracted, setIsPasswordConfirmInteracted] = useState(false);

  const [passwordError, setPasswordError] = useState<PasswordError>({
    minLength: false,
    uppercase: false,
    number: false,
    symbol: false,
  });
  const [role, setRole] = useState<RolesEnum | null>(null);
  const [workspaceId, setWorkspaceId] = useState(0);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [isPasswordConfirmFocused, setIsPasswordConfirmFocused] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [searchParams] = useSearchParams();
  const { handleOpenToast } = useContext(ToastContext);
  const invitation = searchParams.get('invitation');

  const dispatch = useDispatch();

  const [validateInvitationLink] = useValidateInvitationLinkMutation();
  const [addDataLead] = useAddDataLeadMutation();
  const [addDataCollector] = useAddDataCollectorMutation();
  const [addAdmin] = useAddAdminMutation();

  const tempToken = useRef<string | null>(null);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = event.target.value;
    setLastName(newLastName);
  };

  const passwordRequirements = [
    {
      id: 'minLength',
      text: 'At least 12 characters long',
      isValid: (password: string) => password.length >= 12,
    },
    {
      id: 'uppercase',
      text: 'At least one uppercase letter',
      isValid: (password: string) => /[A-Z]/.test(password),
    },
    {
      id: 'number',
      text: 'At least one number',
      isValid: (password: string) => /\d/.test(password),
    },
    {
      id: 'symbol',
      text: 'At least one symbol',
      isValid: (password: string) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
  ];

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    updatePasswordErrors(newPassword);
  };

  const updatePasswordErrors = useCallback((password: string) => {
    setPasswordError({
      minLength: password.length >= 12,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
    setIsPasswordInteracted(true);
  };

  const handlePasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPasswordConfirm = event.target.value;
    setPasswordConfirm(newPasswordConfirm);
    setPasswordConfirmError(newPasswordConfirm !== password);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handlePasswordConfirmFocus = () => {
    setIsPasswordConfirmFocused(true);
  };

  const handlePasswordConfirmBlur = () => {
    setIsPasswordConfirmFocused(false);
    setIsPasswordConfirmInteracted(true);
  };

  const isFormValid =
    password !== '' && passwordConfirm !== '' && Object.values(passwordError).every(Boolean) && !passwordConfirmError;

  const handleSubmit = async (event: React.FormEvent) => {
    if (tempToken.current === null) return;
    event.preventDefault();

    const user: User = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    };
    try {
      if (role === RolesEnum.DataLead) {
        await addDataLead({ ...user, tempToken: tempToken.current }).unwrap();
      } else if (role === RolesEnum.DataCollector) {
        await addDataCollector({ ...user, tempToken: tempToken.current }).unwrap();
      } else if (role === RolesEnum.Admin) {
        await addAdmin({ ...user, tempToken: tempToken.current }).unwrap();
      } else {
        handleOpenToast({
          severity: 'error',
          message: 'Invalid role on the invitation, contact your system administrator',
        });
      }
      handleOpenToast({
        severity: 'success',
        message: 'User created successfully',
      });
      navigate('/');
    } catch {
      handleOpenToast({
        severity: 'error',
        message: 'Failed to create user, contact your system administrator',
      });
    }
  };

  useEffect(() => {
    if (invitation === null) return;
    dispatch(clearAuth());

    const validateInvitation = async () => {
      try {
        const validateInvitationResponse = await validateInvitationLink({ identifier: invitation }).unwrap();
        if (validateInvitationResponse == null) return;
        const { role, email, token } = validateInvitationResponse;
        tempToken.current = token.access_token;
        setRole(role as RolesEnum);
        setEmail(email);
        setWorkspaceId(workspaceId);
      } catch {
        handleOpenToast({ severity: 'error', message: 'Invalid invite, contact your system administrator' });
      }
    };

    void validateInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation, validateInvitationLink]);

  useEffect(() => {
    updatePasswordErrors(password);
    setPasswordConfirmError(passwordConfirm !== password);
  }, [password, passwordConfirm, updatePasswordErrors]);

  return (
    <AppLayout>
      <AccountHeader variant="page" />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: palette.gray.white,
            padding: (theme) => `${theme.spacing(3)}`,
            gap: (theme) => theme.spacing(3),
            border: `1px solid ${palette.gray[300]}`,
            borderRadius: '8px',
            width: '400px',
          }}
        >
          <Typography variant="displaySmall" sx={{ color: palette.green[700] }}>
            Create a Password
          </Typography>
          <Typography variant="bodyLarge" sx={{ color: palette.gray.black }}>
            Create a password to access the REBEX Portal.
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <AppTextField
              id="email"
              label="Email (Username)"
              value={email}
              variant="outlined"
              required
              disabled
              labelVariant="labelLarge"
              sx={{ width: '100%', borderRadius: '4px', mb: 4 }}
            />
            <AppTextField
              id="first-name"
              label="First Name"
              placeholder="First Name"
              value={firstName}
              variant="outlined"
              required
              onChange={handleFirstNameChange}
              labelVariant="labelLarge"
              sx={{ width: '100%', borderRadius: '4px', mb: 4 }}
            />
            <AppTextField
              id="last-name"
              label="Last Name"
              placeholder="Last Name"
              value={lastName}
              variant="outlined"
              required
              onChange={handleLastNameChange}
              labelVariant="labelLarge"
              sx={{ width: '100%', borderRadius: '4px', mb: 4 }}
            />
            <AppTextField
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              variant="outlined"
              error={isPasswordInteracted && Object.values(passwordError).some((isValid) => !(isValid as boolean))}
              required
              onChange={handlePasswordChange}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              labelVariant="labelLarge"
              sx={{ width: '100%', borderRadius: '4px' }}
            />
            {isPasswordInteracted && (
              <Stack spacing={1} sx={{ mt: 1 }}>
                {passwordRequirements.map((req) => (
                  <Typography
                    variant="bodySmall"
                    key={req.id}
                    sx={{ color: `${passwordError[req.id as keyof PasswordError] ? 'green' : 'red'} !important` }}
                  >
                    {req.text}
                  </Typography>
                ))}
              </Stack>
            )}
            <Box sx={{ mt: 4 }}>
              <AppTextField
                id="password-confirm"
                label="Re-enter Password"
                type={showPasswordConfirm ? 'text' : 'password'}
                placeholder="Password"
                value={passwordConfirm}
                variant="outlined"
                error={isPasswordConfirmInteracted && passwordConfirmError}
                helperText={passwordConfirmError && isPasswordConfirmInteracted ? 'Passwords do not match.' : ''}
                required
                onChange={handlePasswordConfirmChange}
                onFocus={handlePasswordConfirmFocus}
                onBlur={handlePasswordConfirmBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showPasswordConfirm ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                labelVariant="labelLarge"
                sx={{ width: '100%', borderRadius: '4px' }}
              />
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <AppButton
              variant="contained"
              disabled={!isFormValid}
              sx={{
                backgroundColor: palette.green[500],
                borderRadius: '100px',
                '&:hover, &:active, &:focus': { backgroundColor: palette.green[500] },
              }}
              onClick={(e) => {
                void handleSubmit(e);
              }}
            >
              <Typography variant="buttonMedium">Create Account</Typography>
            </AppButton>
          </Box>
        </Box>
      </Container>
    </AppLayout>
  );
};

export default AccountCreation;
