import React, { Fragment, useRef, useEffect } from 'react';
import classes from './ChatBox.module.css';
import { DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import chatBot from '../../../../assets/svg/chatBot.svg';
import ChatMessage from './ChatMessage';
import { useSendMessageMutation } from '../../../../utils/redux/endpoints/chatbotEndpoints';
import type { IChat, IChatResponse } from '../../../../utils/types/ChatBot';

interface IChatBoxProps {
  setIsChatDialogOpen: (value: boolean) => void;
}

const randomQuestions: string[] = [
  '% of customers who use Mobile banking app more than once a week in Germany?',
  'What % of Low income Male customers using ATM globally are under 40?',
  'What are the top 3 most popular channels used by customers in Germany under the age of 35?',
  'Which bank has the highest proportion of customers using mobile app in Spain?',
  'Which highest education qualification segment visit branches the most in Germany?',
];

const ChatBox: React.FC<IChatBoxProps> = ({ setIsChatDialogOpen }) => {
  const [message, setMessage] = React.useState<string>('');
  const [chatMessages, setChatMessages] = React.useState<any[]>([]);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [isChatLoading, setIsChatLoading] = React.useState<boolean>(false);
  const [sendMessage] = useSendMessageMutation(); // Removed unused vars isLoading and error

  useEffect(() => {
    const savedMessages = sessionStorage.getItem('chatMessages');
    if (savedMessages !== null) {
      setChatMessages(JSON.parse(savedMessages) as any[]);
    }
  }, []);

  // Save messages to session storage whenever chatMessages changes
  useEffect(() => {
    if (chatMessages.length > 0) {
      sessionStorage.setItem('chatMessages', JSON.stringify(chatMessages));
    }
  }, [chatMessages]);

  const sendMessageHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (message.trim() === '') return;
    setChatMessages([...chatMessages, { id: 'user', message }]);
    setMessage('');
    setIsChatLoading(true);
    setChatMessages((prevMessages) => [...prevMessages, { id: 'genie', message: 'Thinking' }]);
    await postNewMessageRequest(message);
  };

  const postNewMessageRequest = async (message: string) => {
    try {
      const payload = { message };
      const response: IChatResponse = await sendMessage(payload).unwrap();

      if (response?.chart?.is_chart === true) {
        // Use optional chaining for null checks
        const { categories, values } = response.chart.data;
        const chartMessage = response.chart?.message !== undefined ? response.chart?.message : 'Here is your chart';
        console.log('chartMessage:', chartMessage);

        setChatMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          {
            id: 'genie',
            message: response.message ?? '',
            is_chart: true, // Fix: Use is_chart, not isChart
            chart: { categories, values, chartMessage }, // Ensure chartMessage is present
          },
        ]);
      } else {
        setChatMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { id: 'genie', message: response.message ?? '', is_chart: false },
        ]);
      }

      setIsChatLoading(false);
    } catch (err) {
      console.error('Failed to send message:', err);
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  return (
    <Fragment>
      <DialogTitle
        sx={{
          backgroundColor: '#0d3f1b',
          color: '#fff',
          padding: 'auto 0.5rem !important',
          display: 'flex',
          gap: '.5rem',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">Chat with Genie </Typography>
      </DialogTitle>
      <DialogContent>
        {chatMessages.length > 0 ? (
          <div className={classes.chat_wrapper}>
            {chatMessages.map((chat, index) => (
              <ChatMessage
                key={index} // Added key prop
                id={chat.id}
                message={chat.message}
                isLastIndex={index === chatMessages.length - 1}
                isChatLoading={isChatLoading}
                isChart={chat.is_chart} // Use is_chart, not isChart
                chart={
                  chat.chart !== undefined
                    ? {
                        categories: chat.chart.categories,
                        values: chat.chart.values,
                        chartMessage: chat.chart.chartMessage,
                      }
                    : undefined
                }
              />
            ))}
            <div ref={chatEndRef} />
          </div>
        ) : (
          <section className={classes.no_chat_data}>
            <img src={chatBot} alt="chat icon" height="60px" width="60px" />
            <h3>Hi, I am Genie. I can help you with consumer survey information only.</h3>
            <Typography sx={{ marginTop: '0' }}>Ask me Anything like:</Typography>
            <ul>
              {randomQuestions.map((question, index) => (
                <li
                  key={index} // Added key prop
                  className={classes.list_item}
                  onClick={() => {
                    setMessage(question);
                  }}
                >
                  {question}
                </li>
              ))}
            </ul>
          </section>
        )}
      </DialogContent>

      <DialogActions>
        <form className={classes.form_wrapper} onSubmit={sendMessageHandler}>
          <div className={classes.input_message_wrapper}>
            <TextField
              value={message}
              disabled={isChatLoading}
              fullWidth
              size="small"
              placeholder="Ask me anything"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMessage(event.target.value);
              }}
            />
          </div>
          <div className={classes.chat_action_wrapper}>
            <IconButton
              sx={{ color: '#0d3f1b' }}
              disabled={message.trim() === ''}
              type="submit"
              aria-label="Send Message"
            >
              <SendIcon />
            </IconButton>
          </div>
        </form>
      </DialogActions>
    </Fragment>
  );
};

export default ChatBox;
